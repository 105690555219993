import React from 'react'
import { Link } from 'gatsby'

const toggleMobile = () => {
  const serviceSubmenu = document.getElementById('service-submenu')
  const serviceMenu = getComputedStyle(serviceSubmenu).getPropertyValue('--service-submenu');
  if(window.innerWidth < 1024) {
    if(serviceMenu === 'flex'){
       serviceSubmenu.style.setProperty('--service-submenu', 'none');
    }
     else {
      serviceSubmenu.style.setProperty('--service-submenu', 'flex');
     }
  }
}

const closeMenu = () => document.getElementById('navbar').style.setProperty('--menu-display', 'none');


export default () => (
  <nav id="navbar" className="navbar">
    <ul className="navbar_list">
      <li><Link onClick={closeMenu} to='/#about' className="navbar_link">ABOUT</Link></li>
      <li className="navbar_services-li">
        <span onClick={toggleMobile}  id="services" className="navbar_link">SERVICES</span>
        <ul id="service-submenu" className="navbar_service">
          <li className="service--advertise"><Link to='/advertise-with-us/'>Advertise With Us</Link></li>
          <li className="service--seo"><Link to="/google-adwords/">Marketing Services</Link></li>
          <li className="service--seo"><Link to="/creative-services/">Creative Services</Link></li>
          <li className="service--tvandradio"><Link to="/tv-radio/">TV and Radio</Link></li>
        </ul>
      </li>
      <li><Link onClick={closeMenu} to='/#work' className="navbar_link">WORK</Link></li>
      <li><Link onClick={closeMenu} to='/contact/' className="navbar_link navbar_link--contact">CONTACT</Link></li>
      {/* <Link to='blog' className="navbar_link">BLOG</Link> */}
    </ul>
  </nav>
)