import React from 'react'
import logo from '../images/logo.png'
import {Link} from 'gatsby'

export default () => (
  <footer id="footer" className="footer">
    <img id="footer-logo" src = {logo} alt = "WRAL Digital Solutions Logo"/>
    <div className="footer_links">
        <a href="tel:+1919-744-3030">919-744-3030</a>
        <a href="mailto:info@wraldigitalsolutions.com">info@wraldigitalsolutions.com</a>
        <a href="https://www.google.com/maps/search/?api=1&query=WRAL+Digital+Solutions">2619 Western Blvd, Raleigh, NC, 27606</a>
    </div>
    <div className="footer_footer">
      <p> © 2020 WRAL Digital Solutions</p>
      <a href="https://www.capitolbroadcasting.com/privacy-policy/">Privacy Policy</a>
      <a href="https://www.capitolbroadcasting.com/terms-of-use/">Terms of Use</a>
      <Link to="/terms-conditions/">Client Terms and Conditions</Link>
    </div>
  </footer>
)