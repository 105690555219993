import React from 'react'
import Navbar from '../components/Navbar'
import logo from '../images/logo.png'
import logoFull from '../images/logo-full.png'
import {Link} from 'gatsby'

export default () => (
  <header>
    <Link to="/">
      <img className="logo" src={logo} alt="logo"/>
      <img className="logo--full" src={logoFull} alt="logo"/>
    </Link>
    
      <Navbar/>
  </header>
)
