import React from 'react'
import menu from '../images/menu.svg'

const toggleMenu = () => {
  const menu = document.getElementById('navbar');
  const state = getComputedStyle(menu).getPropertyValue('--navbar-offset');

  if(state !== '0') {
    menu.style.setProperty('--navbar-offset', '0');
    menu.style.setProperty('--menu-overlay-opacity', '1');
    menu.style.setProperty('--menu-display', 'block');
    
  } else {
    menu.style.setProperty('--navbar-offset', '-70vw');
    menu.style.setProperty('--menu-overlay-opacity', '0');
    menu.style.setProperty('--menu-display', 'none');
  }
}

export default () => (
  <span className="mobile-header">
    <img id="icon-menu" onClick={toggleMenu} src={menu} alt="menu"/>
  </span>
)